/* eslint-disable */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './styled.css';
import { Link } from 'gatsby';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import ServicesForm from '../../SevicesForm';

const ContactDigital = ({ location }) => {
  const ContactDigitalImages = useStaticQuery(graphql`
    query ContactDigitalImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='custard spb-8'>
      <Container>
        <Row>
          <Col lg='6' className='splitcol'>
            <h2>
              Find out how we’re <br /> helping our partners <br /> confront the
              challenges <br /> of COVID-19.
            </h2>

            <div className='team-grid'>
              <div>
                <div class='circle-wrapper'>
                  <Image
                    className='img-fluid'
                    sizes={ContactDigitalImages.louis.childImageSharp.sizes}
                    alt='Louis Bester'
                  />
                </div>
                <h6>Louis Bester</h6>
                <span>Product Owner</span>
              </div>
              <div>
                <div class='circle-wrapper'>
                  <Image
                    className='img-fluid'
                    sizes={ContactDigitalImages.jay.childImageSharp.sizes}
                    alt='Jay Thomson'
                  />
                </div>
                <h6>Jay Thomson</h6>
                <span>Managing Partner</span>
              </div>
              <div>
                <div class='circle-wrapper'>
                  <Image
                    className='img-fluid'
                    sizes={ContactDigitalImages.anton.childImageSharp.sizes}
                    alt='Anton Moulder'
                  />
                </div>
                <h6>Anton Moulder</h6>
                <span>Managing partner</span>
              </div>
            </div>
          </Col>
          <Col lg='6'>
            <p>
              Please enter your details here and we'll be in touch to arrange a
              time.
            </p>
            <ServicesForm location={location} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ContactDigital;
