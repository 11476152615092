/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import './styled.css'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const DigitalTopImages = () => {
  const DigitalTopImages = useStaticQuery(graphql`
      query DigitalTopImages {
        top: file(absolutePath: {regex: "/assets/images/services/digitaltransformation/DigitalTransformMainBanner.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2560) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="scontent-wrap introduction">
        <Row className="sp-5">
          <div className="pb-5 col-12">
            <Link
              className="reversearrow"
              to="/services"
            >
              View all services
              <svg
                className="styled__Arrow-d87459-0 bQLhSh"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </div>
          <Col lg="7">
            <h1>Digital transformation is no longer a long-term growth strategy, it’s here now.</h1>
            <p>2030 just got pulled into 2020. We come alongside your teams to help you tackle what was 10 years of real digital transformation in a matter of weeks and months. We make things the right way with fewer project hours invested in presentations and more on continuous delivery.</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <figure>
              <Image className="img-fluid" sizes={DigitalTopImages.top.childImageSharp.sizes} alt="Empathy mapping: one of the methodologies that proved effective for the Barclay’s Africa digital transformation project"/>
              <figcaption>Empathy mapping: one of the methodologies that proved effective for the Barclay’s Africa digital transformation project</figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="spb-10 border-bottom">
          <Col className="testimonial">
            <h2>Urbian has been an integral partner in firmly establishing us as a fin-tech enablement powerhouse within the Liberty and Standard Bank group.</h2>
            <span>Bronwyn Johnson, Business Development Liberty Direct and Standard Bank Direct</span>
          </Col>
        </Row>
      </Container>
    )
  }
export default DigitalTopImages
